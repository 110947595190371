import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogPostListing from "../components/blog_listing"

export default function Feed({ data }) {
  const { posts } = data.blog

  return (
    <Layout pageTitle="Feed">
      <Seo
        title="Feed"
        description="What’s new at Djäkne Startup Studio? Check out our feed for news, cases and articles about best practice for startups."
        keywords="startup studio malmö, startup, entrepreneurs, malmö, scaleup, software companies, startup news"
      />
      <BlogPostListing id="blogposts" posts={posts} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/feed/" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MM/DD/YYYY")
          title
          author
          image
          description
        }
        excerpt
        id
      }
    }
  }
`
