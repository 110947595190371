import * as React from "react"
import { Link } from "gatsby"
import "./header.module.scss"
import arrow from "../images/arrow.svg"

const Menu = ({ menuLinks }) => (
  <div className="menu-container">
    <div className="container-xxl p-0 collapse" id="globalMenu">
      <div className="menu-items mb-5 p-4 col-12 d-flex flex-column">
        <div className="d-flex flex-column  text-md-end">
          {menuLinks.map(link => (
            <Link
              to={link.link}
              key={link.name}
              activeClassName="active"
              className="arrow-button toggleColor py-3 col-12 col-md-4"
            >
              <p className="mb-0 hover-show">{link.name}</p>
              <img alt="arrow" src={arrow} className="mx-3" />
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default Menu
