import * as React from "react"
import { Link } from "gatsby"
import "./blog_listing.module.scss"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

function BlogListing(props) {
  var gutter = "20px"
  return (
    <section id={props.id} className="text-section my-5">
      <div className="container py-4 my-5">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
          <Masonry gutter={gutter}>
            {props.posts.map(post => (
              <div key={post.id} className="post">
                <Link className="py-5" to={post.fields.slug}>
                  {post.frontmatter.image && (
                    <img
                      alt={post.frontmatter.title}
                      src={post.frontmatter.image}
                      className="post-image pb-4"
                    ></img>
                  )}
{/*                   {post.frontmatter.tags.map((tag, i) => [
                    <button
                      type="button"
                      className="team-tag disabled btn btn-primary btn-sm"
                      key={i}
                    >
                      {tag}
                    </button>,
                  ])} */}
                  <h2 className="pt-3 pb-2">{post.frontmatter.title}</h2>
                  <p>{post.frontmatter.description}</p>
                  <p className="mt-5 dimmed-text ">
                    {post.frontmatter.author && (
                      <> {post.frontmatter.author}, </>
                    )}{" "}
                    {post.frontmatter.date}
                  </p>
                </Link>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </section>
  )
}

export default BlogListing
