import * as React from "react"
import { Component } from "react"
import "./header.module.scss"
import { Link } from "gatsby"
import arrow from "../images/arrow.svg"

class Header extends Component {
  handleClick() {
    document.getElementById("nav-icon").classList.toggle("open")
    document
      .getElementById("logo-container")
      .classList.toggle("display-backplate")
  }
  state = {
    class: "navbar navbar-dark bg-transparent fixed-top py-4"
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset
        if (currentScrollPos > 0) {
          this.setState({
            class: "navbar navbar-dark bg-transparent fixed-top py-4 scrolled"
          })
        } else {
          this.setState({
            class: "navbar navbar-dark bg-transparent fixed-top py-4"
          })
        }
      }
    }
  }

  render() {
    return (
      <nav className={this.state.class} id="navbar-fixed-top">
        <div className="container-xxl px-lg-4">
          <div
            id="logo-container"
            className={
              this.props.topImage
                ? `d-flex flex-row align-items-center nav-logo-container top-image`
                : `d-flex flex-row align-items-center nav-logo-container `
            }
          >
            <Link to="/" className="navbar-brand">
              <svg
                fill="none"
                viewBox="0 0 980 308"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#fff" fillRule="evenodd">
                  <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                  <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                  <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                  <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                  <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                  <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
                </g>
              </svg>
            </Link>
            {this.props.parentPage ? (
              <>
                <img alt="arrow" src={arrow} className="me-3" />
                <Link to={`/${this.props.parentPage}`}>
                  <h5 className="page-info me-3 mb-0 back-button">
                    {this.props.parentPageTitle
                      ? this.props.parentPageTitle
                      : this.props.parentPage}
                  </h5>
                </Link>
              </>
            ) : null}
            {this.props.secondParentPage ? (
              <>
                <img alt="arrow" src={arrow} className="me-3" />
                <Link
                  to={`/${this.props.parentPage}/${this.props.secondParentPage}`}
                >
                  <h5 className="page-info me-3 mb-0 back-button">
                    {this.props.secondParentPageTitle
                      ? this.props.secondParentPageTitle
                      : this.props.secondParentPage}
                  </h5>
                </Link>
              </>
            ) : null}

            {this.props.pageTitle ? (
              <>
                <img alt="arrow" src={arrow} className="me-3 mb-0" />
                <h5 className="page-info mb-0 ">
                  {this.props.pageTitle}
                </h5>
              </>
            ) : null}
          </div>
          <div className="buttons-container">
            <a
              className="arrow-button d-none d-md-flex toggleColor pe-3 me-2 contact-us"
              href={`mailto:info@djakne.com?subject=contact us`}
            >
              <p className="mb-0 hover-show">Contact us</p>

            </a>
            <button
              className="btn btn-link"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#globalMenu"
              aria-controls="globalMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.handleClick}
            >
              <div id="nav-icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
