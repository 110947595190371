import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Menu from "./menu"

const Layout = ({
  children,
  pageTitle,
  topImage,
  parentPage,
  parentPageTitle,
  secondParentPage,
  secondParentPageTitle,
  customHeaderFooter
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            description
          }
        }
      }
    }
  `)

  return (
    <>
      {!customHeaderFooter && (
        <Header
          title={data.site.siteMetadata?.title || `Title`}
          parentPage={parentPage}
          parentPageTitle={parentPageTitle}
          secondParentPage={secondParentPage}
          secondParentPageTitle={secondParentPageTitle}
          pageTitle={pageTitle}
          topImage={topImage}
          customHeaderFooter={customHeaderFooter}
        />
      )}
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="1wmqx72pytgimagzyis70ym1h89zb8"
          />
          <script
            src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.6.0/dist/umd/popper.min.js"
            integrity="sha384-KsvD1yqQ1/1+IA7gi3P0tyJcT3vR+NdBTt13hSJ2lnve8agRGXTTyNaBYmCR/Nwi"
            crossorigin="anonymous"
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.min.js"
            integrity="sha384-nsg8ua9HAw1y0W1btsyWgBklPnCUAFLuTMS2G72MMONqmOymq585AcH49TLBQObG"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <main className="mb-5 mb-md-0">{children}</main>
        {!customHeaderFooter && (
          <>
            <Menu menuLinks={data.site.siteMetadata.menuLinks} />
            <Footer menuLinks={data.site.siteMetadata.menuLinks} />
          </>
        )}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
